<template>
  <div class="container">
    <div class="content">
      <div id="image-preloader" style="display: none">
        <img src="@/assets/images/pressebilder/pressebilde1.jpg" @load="onImgLoad(1)" />
        <img src="@/assets/images/pressebilder/pressebilde2.jpg" @load="onImgLoad(2)" />
        <img src="@/assets/images/pressebilder/pressebilde3.jpg" @load="onImgLoad(3)" />
        <img src="@/assets/images/pressebilder/pressebilde4.jpg" @load="onImgLoad(4)" />
        <img src="@/assets/images/pressebilder/pressebilde5.jpg" @load="onImgLoad(5)" />
        <img src="@/assets/images/pressebilder/pressebilde6.jpg" @load="onImgLoad(6)" />
        <img src="@/assets/images/pressebilder/pressebilde7.jpg" @load="onImgLoad(7)" />
        <img src="@/assets/images/pressebilder/pressebilde8.jpg" @load="onImgLoad(8)" />
      </div>

      <div class="row">
        <div class="col-xs-12  col-md-8 col-md-offset-2 intro center">
          <img src="@/assets/images/andreas-blaa.png" alt="Andreas Wahl" style="margin: auto" /><br />
          Hei. Jeg heter Andreas Wahl, og dette er min side på internettet. <br /><br />Nedenfor finner du svulstige tekster om hvem jeg er og hva jeg gjør. De er skrevet
          i tredjeperson. For det er visst sånn man gjør det på sider som dette på internettet. <br /><br />
          <br />
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12  col-md-8 col-md-offset-2 intro center">
          <b> Andreas Wahl</b> er fysiker, vitenskapsformidler og programleder. Andreas har risikert livet for vitenskapen i Med livet som innsats (NRK), forsøkt å
          opplyse folket gjennom <i>Folkeopplysningen</i> (NRK), skrevet fire populærvitenskapelige bøker, to læreverk i naturfag ( for CappelenDamm) og vunnet
          norgesmesterskapet i morellsteinspytting fire ganger. I tillegg har Andreas funnet opp <i>Julekula</i>, som lyser opp når du blåser på den, produserer
          <i>Vitenkalenderen </i>for tusenvis av nysgjerrige familier hvert år, og turnerer landet med sine vitenshow.
        </div>
      </div>

      <div class="row" style="margin-top: 120px">
        <div class="col-xs-12 col-sm-6 col-md-4 product" id="konferansier">
          <img src="@/assets/images/icon1.svg" />
          <h3>ANDREAS WAHL <br />SOM KONFERANSIER</h3>
          Andreas har betydelig erfaring i å lede arrangementer fra scene. Han leder tekniske og faglige konferanser såvel som folkelige utendørsarrangementer.

          <div class="practical">
            <b>PRAKTISK:</b> <br />
            <i> Varighet: </i> fleksibel <br />
            Norsk eller engelsk<br />
            Kan kombineres med show
          </div>

          <div class="readmore"><router-link class="link bold space" to="/konferansier">☞ Les mer</router-link></div>

          <div class="button-container">
            <router-link class="button" to="/kontakt/konferansier">Send forespørsel</router-link>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 product" id="energisk">
          <img src="@/assets/images/icon2.svg" />
          <h3>ENERGISK <br />– ET VITENSHOW</h3>
          Se sprit, appelsiner og elektroner med nye øyne. På 45 min. utforsker Andreas begrepet «energi» gjennom småspektakulære demonstrasjoner og tankevekkere om
          klima.

          <div class="practical">
            <b>PRAKTISK:</b> <br />
            <i> Varighet: </i> 45 min <br />
            <i>Krever: </i>Bøtte med vann, mopp, projektor og lerret <br />
          </div>

          <div class="readmore"><router-link class="link bold space" to="/energisk">☞ Les mer</router-link></div>

          <div class="button-container">
            <router-link class="button" to="/kontakt/energisk">Send forespørsel</router-link>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 product" id="seoikult">
          <img src="@/assets/images/icon3.svg" />
          <h3>SE!OI!KULT!</h3>
          <i>Se!Oi!Kult!</i> er et inspirasjonsforedrag for lærere eller barnehageansatte. Målet er å hjelpe den enkelte lærer til å skape opplevelser med elevene som
          skaper nysgjerrighet og forventninger.

          <div class="practical">
            <b>PRAKTISK:</b><br />
            <i> Varighet:</i> 45-120 min (inkl pauser) <br />Norsk eller engelsk <br />
            <i>Krever:</i> Scene og lydanlegg <br />som gjør at alle ser og hører godt<br />
          </div>

          <div class="readmore"><router-link class="link bold space" to="/seoikult">☞ Les mer</router-link></div>

          <div class="button-container">
            <router-link class="button" to="/kontakt/seoikult">Send forespørsel</router-link>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 product" id="vannvittig">
          <img src="@/assets/images/icon4.svg" />
          <h3>VANNVITTIG VITENSKAP</h3>
          VannVittigVitenskap er nerdete teambuilding, ledet av Andreas. Deltakerne konkurrerer i forskningsoppgaver på veldig, veldig lavt nivå.

          <div class="practical">
            <b>PRAKTISK:</b> <br />
            <i> Varighet:</i> 60–90 minutter <br />Norsk eller engelsk <br /><i>Antall:</i> 20-200 personer <br />
            <i>Krever:</i> Innendørs- og utendørslokaler<br />
          </div>

          <div class="readmore"><router-link class="link bold space" to="/vannvittig">☞ Les mer</router-link></div>

          <div class="button-container">
            <router-link class="button" to="/kontakt/vannvittig">Send forespørsel</router-link>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 product" id="universet">
          <img src="@/assets/images/icon5.svg" />
          <h3>UNIVERSET PÅ 42 MINUTTER</h3>
          <i> Universet på 42 minutter </i> et vitenshow som tar for seg universet, fra dets begynnelse og frem til de minste bestanddelene vi kjenner i dag.

          <div class="practical">
            <b>PRAKTISK:</b>
            <br />
            <i> Varighet:</i> 42 minutter + ev. tid til spørsmål <br />Norsk eller engelsk <br /><i>Krever:</i> Projektor og lerret <br />
          </div>

          <div class="readmore"><router-link class="link bold space" to="/universet">☞ Les mer</router-link></div>

          <div class="button-container">
            <router-link class="button" to="/kontakt/universet">Send forespørsel</router-link>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 product" id="joss">
          <img src="@/assets/images/icon6.svg" />
          <h3>JØSS‽</h3>
          <i>Jøss‽</i> er en podcast der 3-4 raringer møtes for å fekte med funfacts. Du kan lytte til Jøss‽ når det måtte passe deg, eller booke Jøss‽ LIVE til ditt
          neste arrangement.

          <div class="practical">
            <b>PRAKTISK:</b> <br />
            <i> Varighet:</i> 30-60 min <br /><i>Krever:</i> Scene, lyd og projektor<br />
          </div>

          <div class="readmore"><a href="https://www.buzzsprout.com/940189" target="_blank" class="link bold space" to="/joss">☞ Les mer</a></div>
          <div class="button-container">
            <router-link class="button" to="/kontakt/joss">Send forespørsel</router-link>
          </div>
        </div>
      </div>

      <div class="row" style="margin: 40px 0 180px 0">
        <div class="col-xs-12  col-md-8 col-md-offset-2">
          <div v-if="loaded" class="foto">
            <img class="pic" :src="require(`@/assets/images/pressebilder/${images[image].url}`)" />
            <div class="row">
              <div class="col-xs" style="font-size: 18px"><i>Foto:</i> {{ images[image].creds }}</div>
              <div class="col-xs" style="text-align: center; cursor: pointer"><img @click="download(images[image].url)" src="@/assets/images/download.svg" /></div>
              <div class="col-xs-5">Du står fritt til å bruke bildene i forbindelser med arrangementer der Andreas bidrar. Husk bare å kreditere fotografen.</div>
            </div>
            <div class="link previous" v-if="image > 0" @click="image = image - 1"></div>
            <div class="link next" v-if="image < images.length - 1" @click="image = image + 1"></div>
          </div>

          <div v-else class="center">
            <h2>Laster bildemodul</h2>
            <br /><br />
            <img src="@/assets/images/loading.gif" style="margin: auto; height: 50px" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    image: 0,
    imgArray: [1, 2, 3, 4, 5, 6, 7, 8],
    images: [
      { url: "pressebilde1.jpg", creds: "Linda Varpe" },
      { url: "pressebilde2.jpg", creds: "Linda Varpe" },
      { url: "pressebilde3.jpg", creds: "Linda Varpe" },
      { url: "pressebilde4.jpg", creds: "Linda Varpe" },
      { url: "pressebilde5.jpg", creds: "Linda Varpe" },
      { url: "pressebilde6.jpg", creds: "Linda Varpe" },
      { url: "pressebilde7.jpg", creds: "Linda Varpe" },
      { url: "pressebilde8.jpg", creds: "Linda Varpe" },
    ],
  }),
  computed: {
    loaded() {
      if (this.imgArray.length === 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    onImgLoad(id) {
      for (var i = 0; i < this.imgArray.length; i++) {
        if (this.imgArray[i] === id) {
          this.imgArray.splice(i, 1);
        }
      }
    },
    download(filename) {
      var a = document.createElement("a");
      a.href = require(`@/assets/images/pressebilder/${filename}`);
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
};
</script>

<style lang="scss">
.foto {
  position: relative;

  .pic {
    border-radius: 10px;
    height: 600px;
    width: 100%;
    object-fit: cover;
  }

  .next {
    position: absolute;
    right: 0;
    top: 37%;
    width: 0;
    height: 0;
    display: inline-block;
    border: 25px solid transparent;
    border-left-color: #0094e3;
  }

  .previous {
    position: absolute;
    left: 0;
    top: 37%;
    width: 0;
    height: 0;
    display: inline-block;
    border: 25px solid transparent;
    border-right-color: #0094e3;
  }

  .row {
    margin-top: 25px;

    img {
      width: 40px;
      margin: auto;
    }
  }
}
</style>
